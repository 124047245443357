$color-main: #5e5adb;
$color-primary: #e0e2f7;
$color-secondary: #f4f6fc;

$color-text: #464646; //main text
$color-text-shadow: #9e9e9e; //breadcrumbs
$color-text-grey: #868fa0;
$color-text-high: #1e1e1e; //titles
$color-text-subhigh: #1e1e1e80; //subtitles
$color-highlight: #fff;
$color-border: #e4e4e4;
$success-bg: #e8f4fdff;
$warning-bg: #fae2c7ff;
$warning-msg: #ae5700ff;
$success-msg: #005aa3ff;

$color-shadow: 0 -1px 6px 0 rgba(233, 237, 247, 0.55),
  0 4px 6px 0 rgba(233, 237, 247, 0.55);

$border-radius: 8px;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1440px;

$breakpoints: (
  md: 768px,
  lg: 1024px,
  xl: 1440px,
);

$container-max-widths: (
  md: 768px,
  lg: 1024px,
  xl: 1440px,
);

//-------- landing ---------//
$dark-bg: #252527ff;
$main-dark-bg: #313134;
$light-dark-bg: #454548ff;
$dark-text: #141414;
$light-dark-text: #3e3e3e;
$light-input-text: #cdd1d4;

$oswald-extra-light: "oswaldextralight", sans-serif;
$oswald-light: "oswaldlight", sans-serif;
$oswald-regular: "oswaldregular", sans-serif;
$oswald-heavy: "oswaldheavy", sans-serif;
$outfit-light: "Outfit-Light", sans-serif;
$outfit-regular: "Outfit-Regular", sans-serif;
$outfit-medium: "Outfit-Medium", sans-serif;

$logo-box-height: 107px;
$landing-transition-time: 0.25s;
